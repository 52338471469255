.btn {

	&.btn-lg {
		font-weight: 700;
	}

	&.btn-secondary {
		background-color: $brand-secondary;
		color: #fff;

		&:hover {
			background-color: darken($brand-secondary,10%);
		}
	}

	&.btn-exposants {
		&:before {
			content: "";
			font-family: "FontAwesome";
			margin-right: 5px;
		}
	}

	&.btn-programme {
		&:before {
			content: "";
			font-family: "FontAwesome";
			margin-right: 5px;
		}
	}

	&.btn-plan {
		&:before {
			content: "";
			font-family: "FontAwesome";
			margin-right: 5px;
		}
	}

	&.btn-default {
		background-color: #808080;
		border: none;
		&:hover {
			background-color: #E70114;
		}
	}
}
