.modal-header {
	text-align: center;
}

.modal-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4rem 2rem;

	& > div:first-of-type {
		& > img {
			margin: 0 0 4rem;
			width: 25rem;
			height: auto;
		}
	}
}