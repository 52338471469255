// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

@media(min-width: $screen-md) {
	.extra-padding-right {
		> .vc_column-inner  {
			padding-right: 60px;
		}
	}

	.border-right-primary {
		> .vc_column-inner {
			border-right: 1px solid $brand-primary;
		}
	}
	
	.extra-padding-left {
		> .vc_column-inner  {
			padding-left: 60px;
		}
	}

}

