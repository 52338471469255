// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.form-control {
  border: 1px solid #1870BB;
  color: #808080;
}

.full-screen-search {
	z-index: -10;
	opacity: 0;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(255,255,255,0.85);

	transition: all 1s;

	&.opened {
		display: block;
		opacity: 1;
		z-index: 100000;
	}

	.wrapper {
		position: relative;
		height: 100%;
		width: 100%;

		.close-full-search {
			position: absolute;
			top: 15px;
			right: 15px;

			&:before {
				content: "";
				font-family: "FontAwesome";
				font-size: 40px;
				color: $brand-secondary;
				cursor: pointer;
			}
		}

		form {
			position: absolute;
			top: 50%;
			transform: translate(-50%,-50%);
			left: 50%;
			width: 100%;
			max-width: 600px;
		}
	}
}

    .wpcf7-validation-errors, .wpcf7-mail-sent-ng {
	background-color: #f2dede;
	border-color: #eed3d7 !important;
	color: #b94a48;
	clear: both;
	margin: 15px 0 0;
	overflow: hidden;
	padding: 8px 10px;
}

.wpcf7-not-valid-tip {
	color: #b94a48 !important;
	font-size: 1.4rem !important;
}

    .wpcf7-mail-sent-ok {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #468847;
	clear: both;
	margin: 15px 0 0;
	overflow: hidden;
	padding: 10px;
}

.wpcf7-list-item-label {
	&:before {
		margin-left: .5rem;
	}
}
