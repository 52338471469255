.section--map {
	background-image: url('../images/img-bg-urbest-1-min.jpg');
	background-position: center center;
	background-size: cover;

	padding-top: 60px;

	h3 {
		color: #fff;
	}
}

.block--gmap {
	height: 300px;
	margin-bottom: 60px;
}

.block--news {
	padding-bottom: 60px;
	.wrapper-news {

		background: $brand-primary;
		padding: 30px;
		border-radius: 5px;
		margin-bottom: 30px;

		.date {
			color: #fff;
			//font-size: 16px;
		}

		.titre, .titre a {
			color: #51A5ED;
			font-size: 20px;
			margin-bottom: 15px;
		}

		.excerpt p {
			color: #fff;
			font-size: 12px;
			margin-bottom: 15px;
		}

		.read-more a {
			color: #51A5ED;
		}
	}
}

.section--owl-partenaires {
	padding: 60px 0;

	.title {
		margin-bottom: 30px;
		font-weight: 400;
	}
}

.section--page-header {
	height: 262px;
	background-position: center center;
	background-size: cover;
	position: relative;
	border-bottom: 12px solid $brand-primary;

	.degrade-blanc {
		@include degrade-blanc;
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
	}

	> .container {
		position: relative;
		height: 100%;

		& > div {
			position: absolute;
			left: 0;
			top: 65%;
			transform: translateY(-50%);

			.title {
				color: #fff;
				font-size: 4.5rem !important;
				font-weight: 400;
				text-shadow: 0 2px 4px rgba(0,0,0,0.6);

				@media(max-width: $screen-sm) {
					padding-left: 15px;
				}

			}

			.sous-titre {
				color: #fff;
				font-weight: 300;
				text-shadow: 0 2px 4px rgba(0,0,0,0.6);
				@media(max-width: $screen-sm) {
					padding-left: 15px;
				}
			}
		}
	}
}

.breadcrumb-top {
	color: $gray-light;
	margin: 2rem 0;
}
