.archive {
	.container-article {
		
		&:nth-child(odd) {
			.row {
				//background: #eee;
			}
		}
		
		.row {
			padding: 45px 0;
		}
	
				
			
			article {
				h2, h2 a {
					font-size: 32px;
					font-weight: 400;
					color: #707070;
					margin-top: 0;
					
					@media(max-width: $screen-sm) {
						//text-align: center;
					}
		
				}
				
				.excerpt p {
					font-weight: 300;
					font-size: 16px;
					
					@media(max-width: $screen-sm) {
						//text-align: center;
					}
				}
				
				.read-more {
					margin-bottom: 0;
					
					@media(max-width: $screen-sm) {
						//text-align: center;
					}
				}
			}
	
	}
}

.single {
}

.single, .archive {
	.container-date {
		//border: 1px solid #000;
		//border-radius: 50px;
		//width: 100px;
		//height: 100px;
		background: $brand-primary;
		display: inline-block;
		padding: 5px;
		margin-bottom: 15px;
		
		.jour {
			color: #fff;
			font-weight: 300;
			font-size: 40px;
			text-align: center;
			margin-bottom: 0;
			line-height: 40px;
		}
		
		.mois {
			color: #fff;
			font-weight: 700;
			font-size: 22px;
			line-height: 22px;
			margin-bottom: 0;
			text-align: center;
			//text-transform: uppercase;
		}
		
		.annee {
			color: #fff;
			font-weight: 700;
			font-size: 16px;
			line-height: 16px;
			margin-bottom: 0;
			text-align: center;
		}
		
		@media(max-width: $screen-sm) {
			.jour, .mois, .annee {
				display: inline-block;
				font-size: 16px;
				line-height: 18px;
				font-weight: 300;
			}
		}
	}
	
}