header.banner {
	position: fixed;
	background-color: transparent;
	z-index: 1000;
	left: 0;
	right: 0;
	padding-top: 15px;
	transition: all 1s;

	&.is_stuck {
		background-color: rgba(255,255,255,0.95);
	}

	a.brand {

		display: inline-block;
		//position: relative;

		@media(max-width: $grid-float-breakpoint) {
			display: none;
		}

		img {
			width: 175px;
			height: auto;
		}
	}


		.logo-mobile {

			position: relative;
			display: inline-block;
			width: 130px;
			height: auto;
			margin-left: 15px;
			img {
				max-width: 175px;
			}

			@media(min-width: $grid-float-breakpoint) {
				display: none;
			}
			img {

			}
		}

	button.navbar-toggle {

		  border: none;
		  background: transparent !important;

		  &:hover {
		    background: transparent !important;
		  }

		  .icon-bar {
		    width: 22px;
		    transition: all 0.2s;
		  }
		  .top-bar {
		    transform: rotate(45deg);
		    transform-origin: 10% 10%;
		  }
		  .middle-bar {
		    opacity: 0;
		  }
		  .bottom-bar {
		    transform: rotate(-45deg);
		    transform-origin: 10% 90%;
		  }

		  &.collapsed {
			    .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}
	}

	.navbar-default, .navbar-default .navbar-collapse, .navbar-default .navbar-form {
		border: none;
	}

	nav {
		position: relative;

		.navbar-nav {
			display: flex;
			height: 100%;
		}

		ul.nav {

			@media(min-width: $grid-float-breakpoint) {
				position: absolute;
				right: 0;
				bottom: 0;

				.dropdown:hover .dropdown-menu {
				    display: block;
				}
				.dropdown-menu {
				    margin-top: 0px;
				}
			}

			@media(max-width: $grid-float-breakpoint) {
				padding: 0 15px;
				display: block;
			}

			li {
				a {
					//font-family: $headings-font-family;
				}
			}

			li.current-menu-ancestor, li.current-menu-parent {
				> a {
					//color: $rose;
				}
				border-bottom: 2px solid $brand-primary;
			}

			> li {
				margin-top: auto;
    		margin-bottom: auto;
				margin-right: 2rem;
				border-bottom: 1px solid transparent;

				&:last-of-type {
					margin-right: 0;
				}

				&.btn-search {
					&:hover {
						border-bottom: 1px solid transparent;
					}

					> a {
						padding: 10px;

						> i {
							font-size: 30px;
						}
					}
				}

				@media(min-width: $grid-float-breakpoint) {
					&:hover {
						border-bottom: 2px solid $brand-primary;
					}
				}

				> a {
					font-weight: 400;
					font-size: 16px;
					cursor: pointer;
					padding: 1rem 0;
				}

				> ul {
					border: 0 none;
					box-shadow: none;
				}
			}
		}
	}
}
