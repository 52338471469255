.planningSalon-link {
	display: flex;
	justify-content: center;
	background-color: $white;
	margin: 2rem 0;

	& > a {
		margin-right: 2rem;
		font-weight: 300;
		font-size: 2.2rem;

		&:last-of-type {
			margin-right: 0;
		}

		&:focus {
			text-decoration: underline;
			color: $violet;
		}
	}
}

.planningSalon {
	&-title {
		display: flex;
		align-items: center;

		& > p {
			padding: 1rem;
			border-radius: 5px;
			color: $violet;
			margin: 0;
			font-size: 2.4rem;
			font-weight: 300;
		}

		& > hr {
		background-color: #808080;
			margin-left: 1rem;
			flex: 1;
		}
	}

	&-box {
		display: flex;
		margin-bottom: 2rem;
		padding: 2rem 2rem 0;

		& > div:first-of-type {
			width: 40%;
			display: flex;
			justify-content: flex-start;
			//align-items: center;

			& > img {
				border-radius: 50%;
				margin-right: 3rem;
				width: 10rem;
				height: 10rem;
			}

			& > div {

				margin-right: 2rem;

				& > p:first-of-type {
					font-size: 1.6rem;
					font-weight: 400;
					color: $gray-black;
				}

				& > h2 {
					font-size: 2rem;
					font-weight: 700;
					color: $gray-black;
				}

				& > p:last-of-type {
					font-size: 1.8rem;
					color: $violet;
					font-weight: 400;
					text-transform: uppercase;
				}
			}
		}

		& > div:last-of-type {
			width: 60%;
			color: #808080;
		}
	;
	}

	@media all and (max-width: 991px) {
		&-box {
			flex-direction: column;
			justify-content: center;
			align-self: center;
			border-bottom: 1px solid $gray-dark;
			& > div {
				width: 100% !important;
				text-align: center;

				&:first-of-type {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					& > img {
						margin: 0 0 2rem 0;
					}
				}
			}
		}
	}

}
