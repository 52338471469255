.exposant {
	margin: 2rem auto;
	a {
		cursor: pointer;
	}

	&-tax {
		& > div:first-of-type {
			margin: 2rem 0;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}

	&-title {
		display: flex;
		align-items: center;

		& > p {
			padding: 1rem;
			border-radius: 5px;
			color: $violet;
			margin: 0;
			font-size: 3.4rem;
			font-weight: 300;
			text-transform: uppercase;
		}

		& > hr {
			background-color: #808080;
			margin-left: 1rem;
			flex: 1;
		}
	}

	&-box {
		margin: 2rem auto;
		& > div {
			margin: 0 1rem;
			width: 25rem;

			.exposant-boxDetail {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;

				& > div:first-of-type {
					display: flex;
					align-items: center;
					justify-content: center;

					& > a {
						display: block;
					}


				}

				&Title {
					text-align: center;
					& > h2 {
						color: #303030;
						font-size: 1.9rem;
						font-weight: 700;
						margin: 2rem 0;
					}
				}

				&Ville {
					font-weight: 400;
					font-size: 1.4rem;
					text-transform: uppercase;
					margin-bottom: 1rem;
					color: #303030;
				}

				&Hall {
					font-weight: 400;
					font-style: italic;
					text-transform: uppercase;
					color: $violet;
					margin-bottom: 1rem;
					font-size: 2rem;
				}

				&Categories {
					margin: 0;
					color: #808080;
					font-weight: 700;
					margin-bottom: 1rem;
					font-size: 1.4rem;
					& > a {
						color: $violet;
						text-decoration: underline;
					}
				}

				&Description {
					color: #808080;
					font-size: 1.4rem;
					max-width: 30rem;
				}

				&Link {
					display: flex;
					align-items: center;
					justify-content: center;

					& > a {
						& > i {
							color: $violet;
							font-size: 2.5rem;
						}
					}
				}
			}
		}
	}

	.exposantImg {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media all and (max-width: 767px) {
		&-box {
			flex-direction: column;
			justify-content: center;

			&Detail {
				width: 100%;
				margin: 2rem 0 4rem;

				&:last-of-type {
					margin-bottom: 2rem;

				}
			}
		}
	}
}
