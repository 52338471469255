html,body {
	height: 100%;
}

main {
	padding-top: 60px;
}

.gap {
	padding-bottom: 60px;
}

a, .btn {
	@include transition (all 0.3s);
}

.btn {
	//font-weight: 500;
}

img.img-center {
	margin: 0 auto;
}

hr {
	width: 300px;
	background: $brand-secondary;
	height: 1px;
	margin: 45px auto;
}

.texte-primary {
	color: $brand-primary !important;
	p, o li, ul li {
		color: $brand-primary !important;
	}
}

.text-white {
	color: $white;
}

.text-small {
	font-size: 1.4rem;
}

h1,.h1 {
	font-size: 40px;
	font-weight: 400;
	margin-top: 0;
	display: inline-block;
	color: $brand-primary;
}
h2,.h2 {
	font-size: $font-size-h2;
	font-weight: $headings-font-weight;
	margin-top: 0;
	display: inline-block;
	color: $h2-color;
}
h3,.h3 {
	font-size: $font-size-h3;
	font-weight: $headings-font-weight;
	margin-top: 0;
	display: inline-block;
}
h4,.h4 {
	font-size: $font-size-h4;
	font-weight: $headings-font-weight;
	margin-top: 0;
	display: inline-block;
}

p, ol li, ul li {
	font-weight: 300;
}

.sticky {
	position: fixed;
	opacity: .95;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 100;
	border-top: 0;
}


.heading-title {
	//font-family: "Damion";
	//font-size: 50px;
	//color: $bleu;
	//margin: 35px 0;
}

@mixin degrade-blanc {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+41 */
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 41%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 41%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 41%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}

.section--owl-partenaires {
	.owl-item {
		& > li {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.wpb_content_element {
	ul {
		list-style-type: none;

		& > li {
			position: relative;

			&:before {
				font-family: FontAwesome;
				content: '\f111';
				color: $violet;
				position: absolute;
				left: -20px;
			}
		}
	}
}

.partenaires-2 {
	border-left: 1px solid $bleu-fonce;
	padding-left: 2rem;

	@media all and (max-width: 991px) {
		border: none;
	}
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.first-check-field-cf7,
.last-check-field-cf7 {
	.wpcf7-list-item.first {
		margin-left: 0;
	}
}

.last-check-field-cf7 {
	.wpcf7-list-item.first {
		margin-top: 15px;
	}
}
