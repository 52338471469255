.section--slide {
	height: 100%;
	position: relative;


	.container-titre {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 100%;
		max-width: 800px;
		z-index: 2;
		text-align: center;
		padding: 0 15px;

		.grand-titre {
			font-size: 60px;
			text-shadow: 0 2px 4px rgba(0,0,0,0.6);
			font-weight: 700;
			color: #fff;
		}

		.sous-titre {
			color: $white;
			padding: 5px 8px;
			line-height: 6rem;
			font-weight: 400;
			font-size: 36px;
			margin-top: 30px;
			text-shadow: 0 0 10px black;
		}

		@media (max-width: $screen-sm) {
			.grand-titre {
				font-size: 30px;
			}
			.sous-titre {
				font-size: 18px;
			}
		}
	}

	&:before {
		display: block;
		background-color: $brand-primary;
		height: 15px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		content: " ";
	}

	>.container {
		position: relative;

		.wrapper-btn {
			position: absolute;
			z-index: 3;
			bottom: 60px;
			left: 0;
			right: 0;
			text-align: center;

			@media(max-width: $grid-float-breakpoint) {

				bottom: 15px;

				>ul {
					padding: 0 15px;
					margin: auto;

					> li {
						display: block;
						margin-bottom: 10px;

						> a {
							display: block;
						}
					}
				}
			}
		}
	}

	.owl-slide-home {
			height: 100%;
			width: 100%;


		.owl-stage-outer, .owl-stage, .owl-item {
			height: 100%;
		}

		.slide {
			height: 100%;
			background-size: cover;
			background-position: center center;


			.degrade-blanc {
					@include degrade-blanc;
					height: 100%;
			}
		}
	}


}
