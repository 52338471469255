.search-results {
	article {
		h2 {
			color: $brand-primary;
		}
	}
}

.single {
	article {
		margin-top: 45px;
		
		nav.post-nav {
			margin: 45px 0;
		}
	}
}