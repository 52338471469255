footer.content-info {
	background-color: $brand-primary;
	
	>.container {
		padding: 30px 0;
	}
	
	section {
		color: #fff;
		p, ul li, ol li {
			color: #fff;
		}
		
		&.logo {
			> img {
				max-width: 130px;
			}
			margin-bottom: 15px;
		}
		
		&.widget_nav_menu {
			
			.title {
				font-weight: 500;
			}
			
			ul {
				list-style: none;
				padding: 0;

				> li {
					> a {
						color: #fff;
						font-size: 13px;
						
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
	
	.sub-footer {
		background-color: $brand-secondary;
		
		.row > .wrapper {
			display: table;
			width: 100%;
			
			.copyright {
				color: #fff;
				padding: 15px 0;
				display: table-cell;
				width: 50%;
				vertical-align: middle;
				padding-left: 15px;
			}
			
			.logo-gl {
				display: table-cell;
				width: 50%;
				text-align: right;
				vertical-align: middle;
				padding-right: 15px;
				
				>img {
					max-width: 150px;
					display: inline-block;
				}
			}
		}
		
	}
}